.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login{
    background-image: url('./icons/login-desktop-bg.png');
  background-position : center ;
  background-repeat: no-repeat;
  background-size: auto;
}
.login-col, .become-a-client-col{
  padding: 0px;
}
.login-bg{
  height: 610px;
  width: 100%;
  /* background-color: rgba(255,255,255,0.5); */
  max-width: 1225px;
  margin: 0 auto;
}
.login-section{
    position: relative;
    /* left: 105px; */
    background-color: #FFFFFF;
}
.login-section .login-wrapper{
    padding-left: 33px;
    padding-top: 43.25px;
}
.login-section .headerLogin{
  color: #000000;
  font-family: Roboto;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 43px;
}
.login-button-text{
    margin-top:40px;
}
::-webkit-input-placeholder {
  font-size: 14px;
  }
  
  :-moz-placeholder {
    font-size: 14px;
  }
.input .rectangle{
  padding-left: 15px;
}
.login-section .input-field-user-name, .input-field-password{
  height: 96px;
  width: 300px;
  margin-top: 40px;
}
.login-section .input{
  height: 73px;
  width: 300px;
  margin-top: 1px;
}
.login-section .rectangle{
  box-sizing: border-box;
  height: 50px;
  width: 302px;
  border: 2px solid #687583;
  border-radius: 2px;
  background-color: #FFFFFF;
}
.login-section .username, .login-section .password{
    height: 21px;
    width: 170px;
    color: #000000;
    font-family: Roboto Medium;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    display: block;
  }
.login-section .forgot-user-name, .forgot-password{
  height: 21px;
  width: 140px;
  color: #0A4A8D;
  font-family: Roboto Medium;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top:3px;
  margin-left: 16px;
}

.login .login-container{
    padding: 40px 0px 40px 0px;
    display: flex;
}
.login .become-a-client .banner-section {
  height: 496px;
width: 560px;
  border-radius: 0 4px 4px 0;
  background: linear-gradient(328.76deg, #FFFFFF 0%, #D9E3EC 100%);
  min-height: auto;
}
.login .become-a-client .banner-section .banner-caption {
    position: relative;
    top: 0px;
    transform: translateY(0%);
    padding-top: 104.25px;
}
.existing-clients-req{
  color: #000000;
  font-family: Roboto Regular;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  width: 100%;
  margin-top: 20px;
}
.login .get-started{
  height: 56px;
  width: 280px;
  border-radius: 4px;
  background-color: #0A4A8D;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
}
.login .become-a-client .banner-section .banner-caption .banner-title{
  color: #000000;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 43px;
}
.login .become-a-client .banner-section .banner-caption .banner-description{
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 40px;
}
.login .login-container .login-section{
  height: 528px;
  width: 100%;
}
.login .login-container .become-a-client{
    height: 528px;
    /* width: 737px; */
}
.become-a-client .banner-section .banner-img img{
    height: 185.79px;
    width: 371px;
    margin-top: 10px;
}