.login{
  background-image: url('../icons/login-desktop-bg.png');
  background-position : center ;
  background-repeat: no-repeat;
  background-size: auto;
  height: 676px !important
}
.login-bg{
  height: 610px;
  width: 100%;
  /* background-color: rgba(255,255,255,0.5); */
  max-width: 1225px;
  margin: 0 auto;
}
@media all and (max-width: 768px) { 
    .login-section .login-wrapper {
      padding-left: 10px;
    }
    /* .login-section{
        left: 40px;
    } */
    .login .become-a-client .banner-section {
        width: 330px;
    }
    .login .become-a-client .banner-section .banner-content{
        width: 330px;
    }
    .become-a-client .banner-section .banner-img img{
        width: 330px;
        margin-top:58px
    }
    .login .login-container .login-section {
        /* width: 409px; */
        height: 560px;
    }
    .existing-clients-req{
        width: 80%;
    }
    .login .login-container .become-a-client,
    .login .become-a-client .banner-section {
        height: 560px;
    }
    .login-bg{
        height: 610px;
        width: 100%;
        /* background-color: rgba(255,255,255,0.5); */
        
        background-image: url('../icons/login-desktop-bg.png');
        background-position : center ;
        background-repeat: no-repeat;
        background-size: auto;
      }

}

@media all and (max-width: 425px){
  .login{
    background-repeat: inherit;
  }
  .become-a-client{
    position: relative;
    top: 37px;
  }
  .login-bg{
    height: 950px;
    background-image: url('../icons/login-desktop-bg.png');
    background-position : top ;
  }
  .become-a-client{
    margin-top: 50px;
  }
  .become-a-client,.become-a-client .banner-caption{
    background-color: #FFFFFF;
  }
  .banner-section.bc.become-a-client-banner{
    margin: auto;
  }
  .become-a-client .banner-img{
    display: none;
  }
  .login .login-container .become-a-client, .login .become-a-client .banner-section {
    height: auto;
  }
  .login .login-container{
    flex-direction: column;
  }
  .login .login-container .login-section{
    width: 85%;    
  }
  .login-section .rectangle, .login .get-started{
    width: 90%;
  }
  .login-section .input-field-user-name, .input-field-password, .login-section .input{
    width: 100%;
  }
  .login-section {
    margin: auto;
    left: 0;
  }
}
.widgetWrapper{
    width: 40%;
    display: inline-flex;
    margin-left: 100px;
    padding-top: 35px

}
.loginWrapper{
  width: 80%;
  margin-left: 90px;
  max-height: 75% !important;
}
.auth-container {
  margin: auto !important;
}
.beClient{
  display: inline-flex;
  position: absolute !important;
  padding-top: 38px !important;
}
.info{
  width: 99%;
  display: inline-flex;
  margin-left: 100px;
}
#okta-sign-in .mfa-verify-passcode .auth-passcode{
  width: 165px !important;
}
.mfa-verify{
  padding-bottom: 80px !important
}
#okta-sign-in{
  height: 500px !important;
  overflow-y: auto;
}
.forgot-password-email-enabled{
  width: 306px !important;
}
#okta-sign-in .mfa-verify-email .mfa-email-sent-content {
  margin-bottom: 0 !important;
}
.sms-request-button{
  width: 100px !important;
}
.js-help-link{
  display: none;
}
#okta-sign-in.no-beacon .auth-content {
    padding-top: 0px !important;
}
.login .become-a-client .banner-section .banner-caption {
  position: relative;
  top: -52px;
  transform: translateY(0%);
  padding-top: 103.25px;
}
#okta-sign-in .custom-checkbox{
  display: none;
}