.content-container {
    margin: 0 auto;
    max-width: 1225px;
}
.gutter {
    padding: 0 24px;
}
.main-nav {
    align-items: center;
    display: flex;
}
.main-nav nav {
    margin-left: 30px;
}
.main-nav .nav-list {
    list-style: none;
    display: flex;
    padding-left: 0;
}

.main-nav .nav-list .anchor-link {
    font-size: 18px;
    margin: 0 8px;
    padding: 8px 0;
    white-space: nowrap;
}

.main-nav .nav-list .anchor-link:hover {
    color: #0A4A8D;
    text-decoration: none;
    font-weight: 700;
}

.main-nav .nav-list .active {
    border-bottom: 3px solid #0a4a8d;
    font-weight: 500;
}

.main-nav .nav-list .active:hover {
    border-bottom: 3px solid #0a4a8d;
    font-weight: 500;
}
.anchor-link {
    text-decoration: none;
    color: #0A4A8D;
    cursor: pointer;
}
.anchor-link:hover{
    font-weight: 700;
    text-decoration: underline;
}
.mobile-header{
    display: none;
}
.d-flex {
    display: flex;
}

.login-links {
    justify-content: flex-end;
}

.anchor-link {
    text-decoration: none;
    color: #0A4A8D;
    cursor: pointer;
}

.login-links .anchor-link span {
    padding-left: 6px;
    display: inline-block;
    margin-top: 2px;
    font-weight: bold;
    font-size: 16px;
}

.login-links .anchor-link {
    font-size: 18px;
    padding: 5px 20px;
    margin: 0 5px;
    align-items: center;
}

.login-links .login {
    display: flex;
}

.login-links .login span {
    margin-left: 6px;
    display: inline-block;
    line-height: 23px;
}
