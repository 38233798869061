
footer {
    background: #425364;
    color: #fafbff;
}

.footer-header {
    background-color: rgba(29,49,70,0.85);
    padding: 30px 0;
}

.footer-header-content {
    justify-content: space-between;
    align-items: center;
}
@media all and (max-width: 1024px) {

    .main-nav {
        display: block;
    }

    .main-nav .logo {
        margin-top: -35px;
        display: block;
    }

    .main-nav nav {
        margin:  20px 0 30px;
    }

    .main-nav .nav-list li:first-child .anchor-link {
        margin-left: 0;
    }

    .detail-description-container .gutter {
        width: 85%;
    }

    .detail-description-content {
        font-size: 18px;
    }

    .card-container .gutter {
        display: block;
    }

    .client-form .row {
        display: block;
    }

    .client-form .split .form-group {
        width: 100%;
    }

    .client-form .split {
        display: block;
    }

    .client-form .radio-btn-group {
        display: block;
    }

    .footer-address-break {
        display: block;
    }
}

@media all and (max-width: 800px) {
    .detail-description-container .gutter {
        width: 100%;
    }

    .footer-detail .site-map:last-child {
        display: none;
    }

    .footer-address-break {
        display: block;
    }

    img.equal-housing {
        width: 40px;
    }

    img.bankers-mortgage {
        margin-left: 5px;
        width: 70px;
    }
}

@media all and (max-width: 768px) {
    .main-nav .logo {
        margin-top: -20px;
        display: block;
    }
}

@media all and (max-width: 767px) {
    .mobile-header{
        display: block;
        height: 93px;
        margin: 0;
    }
    .header{
        display: none;
    }
    .top-header {
        background-color: #ffffff;
        width: 100%;
        z-index: 99;
        padding-top: 13px;
    }
    .position-relative {
        position: relative!important;
    }
    .flex-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important;
    }
    .utility-links {
        display: none;
        margin: 0 5px;
    }
    .flex-row {
        -ms-flex-direction: row!important;
        flex-direction: row!important;
    }
    .order-1 {
        -ms-flex-order: 1;
        order: 1;
    }
    .flex-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important;
    }
    .navbar {
        width: 100%;
        padding: 0;
    }
    .align-items-end {
        -ms-flex-align: end!important;
        align-items: flex-end!important;
    }
    .top-header .navbar {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center !important;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-top: .5rem;
    }
    .navbar-toggler {
        height: 30px;
        width: 30px;
        background-color: #0a4a8d;
        border-radius: 15px;
        padding: 0;
    }
    .navbar-toggler span:first-child {
        transform-origin: 15% 0%;
    }
    .navbar-toggler span:nth-last-child(1) {
        transform-origin: -15% 0%;
    }
    .navbar-toggler[aria-expanded="true"] span {
        opacity: 1;
        transform: rotate(45deg) translate(2px, -1px);
    }
    .navbar-toggler[aria-expanded="true"] span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    .navbar-toggler[aria-expanded="true"] span:nth-last-child(1) {
        transform: rotate(-45deg) translate(0, 3px);
    }
    .navbar-toggler span {
        display: block;
        width: 16px;
        height: 2px;
        margin: 3px auto;
        position: relative;
        background: #fff;
        border-radius: 2px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }
    .navbar-collapse {
        margin-top: 10px;        
    }
    .navbar-collapse {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-align: center;
        align-items: center;
    }
    .navbar-nav-scroll {
        max-height: fit-content !important;
        position: relative;
        width: 100%;
        overflow-y: scroll;
    }
    .navbar-nav .dropdown {
        /* width: 301px; */
        border-bottom: 1px solid #d9dce0;
    }
    .navbar-nav :last-child {
        /* width: 301px; */
        border-bottom: none;
    }
    
    .dropdown, .dropleft, .dropright, .dropup {
        position: relative;
    }
    #navbarScroll .navbar-nav-scroll a.dropdown-toggle {
        /* color: #1D3146; */
        color: #0a4a8d;
        line-height: 21px;
        font-size: 1.125rem;
        text-decoration: none;
        /* font-weight: 400; */
        padding-left: 0;
        padding-right: 0;
    }
    
    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
    }
    .nav-link {
        display: block;
        padding: .5rem 1rem;
    }
    .dropdown-toggle {
        white-space: nowrap;
    }
    .dropdown-toggle::after{
        display: none;
    }

    .footer-detail-content {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .footer-detail .site-map {
        margin-bottom: 40px;
    }

    .footer .address {
        margin-top: 0;
    }

    .footer .address span {
        line-height: normal;
        letter-spacing: normal;
    }

    .footer .lender-id {
        margin-top: 16px;
        display: flex;
    }

    .footer .equal-housing-images {
        display: block;
        margin: 16px 0;
    }

    .white-dots {
        display: none;
    }

    .client-form-description {
        display: none;
    }

    .testimonial {
        display: block;
    }

    .testimonial .content {
        margin-top: 24px;
        margin-left: 0;
    }

    .testimonial .profile-img {
        text-align: center;
    }

    .main-nav .logo svg {
        width: 150px;
    }
}
.footer-detail-content {
    justify-content: space-between;
    flex-direction: row-reverse;
}
.footer-detail .site-map {
        margin-bottom: 40px;
}
.footer-detail .site-map h3 {
    font-size: 22px;
    line-height: 28px;
    border-bottom: 1px solid #c7d1e4;
    padding-bottom: 1px;
    font-weight: normal;
}
.footer-detail .site-map div a {
    display: block;
    line-height: 28px;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-size: 16px;
    color: #fff;
}
.footer .address {
    margin-top: 40px;
    line-height: 30px;
    letter-spacing: 0.5px;
}
.footer .address a {
    cursor: pointer;
    text-decoration: underline;
    color: #fff;
}
.site-map div a {
    text-decoration: none;
}